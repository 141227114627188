import { Link, Text } from '@atoms/index';
import { sendContextOtp } from '@services/session.service';
import {
    CommonSelectStyleUI,
    brandName,
    getPhoneInputStyle,
    investmentTimeFrameOption,
    isValidMobileNumber,
    supportEmail,
    userCountryCodesInput,
} from '@helpers/constants';
import { regexPattern } from '@helpers/regex';
import { replaceKeysWithValues } from '@helpers/string';
import {
    getAuth,
    getReferralCode,
    isAuthenticated,
    removeReferralCode,
    removeReferralEntityType,
} from '@services/identity.service';
import { checkReferralCode } from '@services/referral.service';
import { validate } from '@services/users.service';
import { Button } from '@ui/atoms/design-system';
import useWindowDimensions from '@ui/hooks/useWindowDimensions';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import { theme } from 'tailwind.config';
import { isNotNullOrUndefined } from '@helpers/utils';
import ConfirmationSignUpModal from '../../ConfirmationSignUpModal';
import texts from './en.json';
import { otpContextEnum } from '@ui/helpers/enum';
import { regexPatterns } from '@ui/helpers/regex';
import { maxNameLength } from '@ui/helpers/constants';
import { handleKeyDown } from '@helpers/miscelleanous';
import { triggerCustomEvent } from '@helpers/miscelleanous';
import { gaEventNameEnum } from '@helpers/enums/gaEventNameEnum';

const LoginModalData = ({
    showEmailInput,
    setShowEmailInput,
    setShowVerifyOtpModal,
    isStatic,
    userInput,
    setUserInput,
    showSignUp,
    setShowSignUp,
    setShowLogin,
    loading,
    setLoading,
    onClose,
    checkReferral = true,
    fromOpportunitySignup,
    switchToSignup,
    setMultipleLogin,
    mobileRef,
    emailRef,
    setOtpModes,
    isNriVisitor = false,
    setModalLoadingState,
    className
}) => {
    const [userIp, setUserIp] = useState('');
    const [message, setMessage] = useState(false);
    const [mobile, setMobile] = useState('');
    const [isMobileValid, setIsMobileValid] = useState(false);
    const [mobileErrorMessage, setMobileErrorMessage] = useState('');
    const [emailId, setEmailId] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [name, setName] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [nameErrorMessage, setNameErrorMessage] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [whatsappOptIn, setWhatsappOptIn] = useState(
        showEmailInput ? true : false
    );
    const [isMultipleAccount, setIsMultipleAccount] = useState(null);
    const [pan, setPan] = useState(false);
    const [isMobileNumberExist, setIsMobileNumberExist] = useState(false);
    const [investmentTimeFrame, setInvestmentTimeFrame] = useState(null);
    const [createNewAccountLoading, setCreateNewAccountLoading] = useState(false);
    const [
        investmentTimeFrameErrorMessage,
        setInvestmentTimeFrameErrorMessage,
    ] = useState(false);
    const [hasTyped, setHasTyped] = useState(false);
    const emailRegex = regexPattern.ALLOW_EMAIL;
    const router = useRouter();
    const { width } = useWindowDimensions();
    const selectRef = useRef();

    useEffect(() => {
        (async () => {
            const isValid = isValidMobileNumber(mobile, {
                countryCode: countryCode,
            });
            setIsMobileValid(isValid);
            if (!isValid) {
                if (showSignUp) {
                    setShowEmailInput(true);
                } else {
                    setShowEmailInput(false);
                }
                setIsMultipleAccount(false)
            }
            if (isValid) {
                setMobileErrorMessage('');
                const response = await validate(mobile);
                setIsMobileNumberExist(response?.status);
                setShowEmailInput(!response.status);
                if (response.status) {
                    if (response.entity?.isActive) {
                        setIsMultipleAccount(response.entity?.multipleAccount);
                        setMobileErrorMessage('');
                    } else {
                        setMobileErrorMessage(
                            replaceKeysWithValues(texts?.DeactivationError, {
                                '{supportEmail}': supportEmail,
                            })
                        );
                    }
                    if (!response.entity?.multipleAccount) {
                        setShowSignUp(false)
                    }
                    if (response.entity?.multipleLogin) {
                        setMultipleLogin(true);
                    }
                } else {
                    setIsMultipleAccount(false);
                    setShowSignUp(true);
                }
            }
        })();
    }, [mobile, countryCode]);

    useEffect(() => {
        if (userInput) {
            if (isNotNullOrUndefined(userInput?.mobile)) {
                setMobile(userInput.mobile);
                setIsMobileValid(isValidMobileNumber(userInput.mobile, {
                    countryCode: userInput?.countryCode,
                }));
            } else {
                setMobile(countryCode);
            }
            if (isNotNullOrUndefined(userInput?.email)) {
                setEmailId(userInput.email);
                if (userInput.email.length > 0)
                    emailError(userInput.email);
            } else {
                setEmailId('');
            }
            if (isNotNullOrUndefined(userInput?.name)) {
                setName(userInput.name);
            }
            else {
                setName('');
            }
            if (isNotNullOrUndefined(userInput?.investmentTimeFrame)) {
                setInvestmentTimeFrame(investmentTimeFrameOption?.find(option => option.value === userInput.investmentTimeFrame));
            } else {
                setInvestmentTimeFrame(null);
            }
            if (isNotNullOrUndefined(userInput?.countryCode)) {
                setCountryCode(userInput.countryCode);
            }
        }
    }, [userInput, investmentTimeFrameOption]);

    useEffect(() => {
        (async () => {
            if (isEmailValid) {
                setEmailErrorMessage(false);
            }
            const auth = getAuth();
            if (await isAuthenticated(auth)) {
                await router.push('/dashboard');
            }
        })();
    }, [isEmailValid]);

    useEffect(() => {
        emailValidation();
    }, [emailId]);

    const emailValidation = (val = emailId) => {
        if (emailRegex.test(val)) {
            setIsEmailValid(true);
            return true;
        } else {
            setIsEmailValid(false);
            return false;
        }
    };

    useEffect(() => {
        setMobileErrorMessage(false);
        setNameErrorMessage(false);
        setEmailErrorMessage(false);
        setInvestmentTimeFrameErrorMessage(false);
    }, [showEmailInput]);

    const handleCreateNewAccount = async () => {
        if (isMobileValid) {
            setCreateNewAccountLoading(true);
            setModalLoadingState(true);
            await router.push(
                `/create-new-account?mobile=${mobile}&countryCode=${countryCode}`
            );
            onClose();
            setCreateNewAccountLoading(false);
            setModalLoadingState(false);
        }
    };

    useEffect(() => {
        if (hasTyped && showEmailInput) {
            triggerCustomEvent(
                gaEventNameEnum.SIGNUP_FORM_START,
            );
        }
    }, [hasTyped]);
    
    const didUserInput = (input) => {
        if (input && !hasTyped && showEmailInput) {
            setHasTyped(true);
        }
    }

    const handleLoginToExistingAccount = () => {
        setShowEmailInput(false);
        setWhatsappOptIn(false);
        setShowSignUp(false);
        setShowLogin(true);
        setMobile(countryCode);
        setName('');
        setInvestmentTimeFrame(null);
        setEmailId('');
    };

    const handleNameChange = (e) => {
        didUserInput (true);
        setName(e.target.value);
        setNameErrorMessage(false);
    };

    const handleNameBlur = (e) => {
        nameError(e.target.value);
    }

    const handleEmailChange = (e) => {
        didUserInput (true);
        setEmailId(e.target.value);
        setEmailErrorMessage(false);
    };

    const handleEmailBlur = (e) => {
        emailError(e.target.value);
    };

    const handleInvestmentTimeFrameChange = (e) => {
        setInvestmentTimeFrame(e);
        setInvestmentTimeFrameErrorMessage(false);
    };

    const handleInvestmentTimeFrameBlur = () => {
        // on mobile devices, react-select triggers the onBlur event 
        // before the state has been updated with the selected value
        setTimeout(() => {
            investmentTimeFrameError(selectRef.current?.getValue()?.[0]?.value);
        }, 0);
    };

    const nameError = (value, errorVisible = true) => {
        if (!value) {
            errorVisible && setNameErrorMessage(texts?.NameIsRequired);
            return true;
        }
        if (!regexPatterns.VALID_NAME_PATTEREN.test(value)) {
            errorVisible && setNameErrorMessage(texts?.EnterValidName);
            return true;
        }
        if (value.length < 2) {
            errorVisible && setNameErrorMessage(texts?.ValidNameIsRequired);
            return true;
        }
        if (value.length > maxNameLength) {
            errorVisible && setNameErrorMessage(
                replaceKeysWithValues(texts?.NameLengthError, {
                    '{length}': maxNameLength,
                }));
            return true;
        }
        setNameErrorMessage(false);
        return false;
    };

    const emailError = (value, errorVisible = true) => {
        if (!value || !value.length > 0) {
            errorVisible && setEmailErrorMessage(texts?.EmailIsRequired);
            return true;
        }
        if (!emailValidation(value)) {
            errorVisible && setEmailErrorMessage(texts?.ValidEmailIsRequired);
            return true;
        }
        setEmailErrorMessage(false);
        return false;
    };

    const investmentTimeFrameError = (value, errorVisible = true) => {
        if (!value) {
            errorVisible &&
                setInvestmentTimeFrameErrorMessage('This is a required field');
            return true;
        }
        setInvestmentTimeFrameErrorMessage(false);
        return false;
    };

    const mobileValidation = () => {
        const isValid = isValidMobileNumber(mobile, {
            countryCode,
        });
        setIsMobileValid(isValid);
        if (!isValid) {
            if (mobile?.length > countryCode.length)
                mobileErrorMessage !==
                    texts?.PleaseEnterAValidPhoneNumber &&
                    setMobileErrorMessage(
                        texts?.PleaseEnterAValidPhoneNumber
                    );
            else setMobileErrorMessage(texts?.PhoneNumberIsRequired);
            if (showSignUp) {
                setShowEmailInput(true);
            } else {
                setShowEmailInput(false);
            }
            setIsMultipleAccount(false)
        }
        return isValid;
    };

    const phoneInputStyle = getPhoneInputStyle(width);

    const submitData = async () => {
        setLoading(true);
        setModalLoadingState(true);
        const referralCode = getReferralCode();
        if (referralCode) {
            const referralCodeData = await checkReferralCode(referralCode);
            if (!referralCodeData.status) {
                setShowConfirmationModal(true);
            } else {
                await signUp(referralCode);
            }
        } else {
            await signUp();
        }
        setLoading(false);
        setModalLoadingState(false);
    };

    const signUp = async (referralCode) => {
        const countryCodeWithPrefix = `+${countryCode}`;
        setUserInput({
            mobile,
            name,
            email: emailId,
            referralCode,
            countryCode,
            countryCodeWithPrefix,
            whatsappOptIn,
            userIp,
            userExists: !showEmailInput,
            documentId: pan,
            investmentTimeFrame: investmentTimeFrame?.value,
        });
        setLoading(true);
        setModalLoadingState(true);
        const response = await Promise.all(
            showEmailInput
                //signup
                ? countryCode === '91'
                    ? [
                        sendContextOtp({
                            mobile,
                            countryCode: countryCodeWithPrefix,
                            context: otpContextEnum.MOBILE_VERIFICATION
                        }),
                        sendContextOtp({
                            mobile,
                            email: emailId,
                            context: otpContextEnum.EMAIL_VERIFICATION
                        })
                    ]
                    : [
                        sendContextOtp({
                            mobile,
                            email: emailId,
                            context: otpContextEnum.EMAIL_VERIFICATION
                        })
                    ]
                //login
                : [
                    sendContextOtp({
                        mobile: mobile,
                        context: otpContextEnum.AUTHENTICATION
                    })
                ]);

        if (response.every(result => result.status)) {
            setShowVerifyOtpModal(true);
            setMessage(false);
            if (!showEmailInput) {
                setOtpModes(response[0].entity);
            }
        } else {
            setMessage((response.find(res => res.message))?.message);
        }
        setLoading(false);
        setModalLoadingState(false);
    };

    const formSubmit = async () => {
        const isNameInvalid = showEmailInput ? nameError(name) : false;
        const isEmailInvalid = showEmailInput ? emailError(emailId) : false;
        const isInvestmentTimeFrameInvalid = showEmailInput
            ? investmentTimeFrameError(investmentTimeFrame)
            : false;
        if (mobile.length <= countryCode.length) {
            setMobileErrorMessage(texts?.MobileNumberIsRequired);
            return;
        } else if (!isMobileValid) {
            setMobileErrorMessage(texts?.PleaseEnterAValidPhoneNumber);
            return;
        }
        if (!isMobileNumberExist && !showEmailInput) {
            setMobileErrorMessage(texts?.NumberDoesNotExistText);
            return;
        }
        if (isNameInvalid && showEmailInput) {
            return;
        }
        if (emailId.length > 0 && !isEmailValid) {
            return;
        }
        if (showEmailInput && isEmailInvalid) {
            return;
        }
        if (showEmailInput && isInvestmentTimeFrameInvalid) {
            return;
        }

        if (isMobileValid) {
            if (!showEmailInput || isEmailValid) {
                // login  || indian signup || NRI signup
                if (!checkReferral) {
                    const referralCode = getReferralCode();
                    if (referralCode) {
                        const referralCodeData = await checkReferralCode(
                            referralCode
                        );
                        if (!referralCodeData.status) {
                            removeReferralCode();
                            removeReferralEntityType();
                        }
                    }
                }
                await submitData();
            }
        }
    };

    const createNewAccount = () => {
        setShowEmailInput(true);
        setWhatsappOptIn(true);
        setIsMultipleAccount(false);
        setIsMobileValid(false);
        setShowSignUp(true);
        setMobile(countryCode);
    };

    const onSwitchLoginAndSignup = () => {
        const isValid = isValidMobileNumber(mobile, {
            countryCode,
        });
        if (isValid) {
            setMobileErrorMessage('');
        }

        if (showEmailInput) {
            handleLoginToExistingAccount();
        } else {
            createNewAccount();
        }
    };

    useEffect(() => {
        /*
            From Signup page only
            Case: If mobile number already exist on login and user clicks on signup
              * Clearing mobile number, and countryCode remain same.
        */
        if (isNotNullOrUndefined(switchToSignup)) {
            setMobile(countryCode);
        }
    }, [switchToSignup]);
    
    // const handleKeyDown = (e) => {
    //     // If backspace is pressed and the whole input is selected
    //     if ((e.key === 'Backspace' || e.key === 'Delete') && e?.target?.selectionStart === 0 && e?.target?.selectionEnd === e?.target?.value?.length) {
    //         e.preventDefault(); // Prevent the default behavior
    //         // Clear only the number portion of the input
    //         setMobile(countryCode)
    //     }
    //     if (e.key === 'Enter') {
    //         formSubmit();
    //     }
    // };

    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    formSubmit();
                }}
                noValidate
                className={className}
            >
                <label
                    className={`block w-full p5-medium mb-2 text-left text-gray-900`}
                >
                    {texts.MobileNumber}
                </label>
                <PhoneInput
                    country={'in'}
                    ref={mobileRef}
                    onChange={(value, country) => {
                        didUserInput (true);
                        setMobile(value);
                        setCountryCode(country?.dialCode);
                        mobileRef.current?.numberInputRef?.focus();
                        setMobileErrorMessage('');
                    }}
                    onKeyDown={(e) => handleKeyDown(e, () => setMobile(countryCode), () => formSubmit())}
                    value={mobile}
                    placeholder={texts.EnterPhoneNumber}
                    onlyCountries={userCountryCodesInput}
                    inputClass={
                        'px-5 h-full p5-regular !border-gray-200 border'
                    }
                    containerClass={`h-12 flex w-full ${mobileErrorMessage ? '!border-semantic-error-base' : '!border-gray-200'}`}
                    containerStyle={phoneInputStyle.containerStyle}
                    countryCodeEditable={false}
                    inputStyle={phoneInputStyle.inputStyle}
                    buttonStyle={phoneInputStyle.buttonStyle}
                    dropdownStyle={phoneInputStyle.dropdownStyle}
                    dropdownClass={`w-full country-list-container ${!isStatic && !showEmailInput ? 'position-fixed' : ''
                        }`}
                    inputProps={{ required: true, id: 'mobileNo' }}
                    onBlur={mobileValidation}
                />
                {mobileErrorMessage && (
                    <div className="mb-2 mt-2 p5-medium text-semantic-error-base">
                        {mobileErrorMessage}
                    </div>
                )}
                {isMultipleAccount && showSignUp && (
                    <Text
                        content={texts.MultipleAccountDesc}
                        weight="500"
                        className="pb-1 p5-regular my-2"
                    />
                )}

                {showEmailInput && (
                    <>
                        <div className="mt-1">
                            <label
                                className={`block w-full p5-medium text-left mt-3 text-gray-900`}
                            >
                                {texts.Name}
                            </label>
                            <input
                                id="name"
                                type="text"
                                value={name}
                                onChange={handleNameChange}
                                onBlur={handleNameBlur}
                                placeholder="Enter your name"
                                required={true}
                                className={`${nameErrorMessage
                                    ? 'border-semantic-error-base'
                                    : 'border-gray-200'
                                    } border-1 focus:border-[0.5px] h-12 w-full px-4 py-3 rounded-lg my-2 box-border p5-medium placeholder:p5-regular placeholder:text-gray-600`}
                            />
                        </div>
                        {nameErrorMessage && (
                            <Text
                                content={nameErrorMessage}
                                weight="500"
                                className="text-semantic-error-base p5-medium"
                            />
                        )}
                        <div className="mt-2">
                            <label
                                className={`block w-full p5-medium text-left text-gray-900`}
                            >
                                {texts.Email}
                            </label>
                            <input
                                id="email"
                                type="email"
                                ref={emailRef}
                                value={emailId}
                                onChange={handleEmailChange}
                                onBlur={handleEmailBlur}
                                placeholder={texts.EnterYourEmail}
                                required={true}
                                className={`${emailErrorMessage
                                    ? 'border-semantic-error-base'
                                    : 'border-gray-200'
                                    } border-1 focus:border-[0.5px] h-12 w-full px-4 py-3 rounded-lg my-2 box-border p5-medium placeholder:p5-regular placeholder:text-gray-600`}
                            />
                        </div>
                        {emailErrorMessage && (
                            <Text
                                content={emailErrorMessage}
                                weight="500"
                                className="text-semantic-error-base p5-medium"
                            />
                        )}

                        <div className="mt-2">
                            <label
                                className={`block w-full p5-medium text-left text-gray-900`}
                            >
                                {texts?.WhenAreYouPlaningToInvest}
                                <Select
                                    id="investmentTimeFrame"
                                    options={investmentTimeFrameOption}
                                    placeholder="Select any one option"
                                    isSearchable={false}
                                    ref={selectRef}
                                    onChange={(e) =>
                                        handleInvestmentTimeFrameChange(e)
                                    }
                                    onBlur={handleInvestmentTimeFrameBlur}
                                    value={investmentTimeFrame}
                                    styles={CommonSelectStyleUI(
                                        width,
                                        '48px',
                                        '1px',
                                        200,
                                        '14px',
                                        theme?.colors?.gray?.[600],
                                        investmentTimeFrameErrorMessage,
                                        'none'
                                    )}
                                    className={`p5-medium react-select-wrapper-class z-50 mt-2 ${investmentTimeFrameErrorMessage
                                        ? 'mb-2'
                                        : 'mb-3'
                                        }`}
                                />
                            </label>
                        </div>

                        {investmentTimeFrameErrorMessage && (
                            <Text
                                content={investmentTimeFrameErrorMessage}
                                className="text-semantic-error-base p5-medium mb-3"
                            />
                        )}

                        <div
                            className={`my-3 text-sm text-left  ${isStatic ? 'xl:mb-4 mt-2' : 'xl:my-2'
                                }`}
                        >
                            {/* Whatsapp OptIn */}

                            <div className="mt-1 mb-2 flex items-center">
                                <input
                                    className={`form-check-input h-4 w-4 border border-gray-200 rounded-sm bg-basicWhite 
                                    checked:bg-primary-500 checked:border-primary-500 checked:!text-primary-500
                                    focus:border-primary-500 focus:outline-none transition duration-200 align-top mr-2 cursor-pointer`}
                                    type="checkbox"
                                    checked={whatsappOptIn}
                                    onChange={() => {
                                        setWhatsappOptIn(!whatsappOptIn);
                                    }}
                                />

                                <label
                                    htmlFor="whatsapp_opt_in"
                                    className={`p6-regular text-gray-900`}
                                >
                                    {texts.ReceiveUpdatesOnWhatsApp}
                                </label>
                            </div>

                            <div className="p6-regular text-gray-600 mt-3 font-medium">
                                <Text
                                    content={texts.BySigningUp}
                                    className="p6-regular"
                                />

                                <ul className="list-disc ml-5">
                                    <li className="mt-1">
                                        {replaceKeysWithValues(texts.ConfirmAgeText, { '{citizenship}': isNriVisitor ? '' : 'and that you are a citizen of India' })}
                                    </li>
                                    <li className="mt-1">
                                        {replaceKeysWithValues(
                                            texts?.callReceiveDisclaimer,
                                            {
                                                '{brandName}': brandName,
                                            }
                                        )}
                                    </li>
                                    <li className="mt-1">
                                        {texts.YouAgreeToThe}
                                        <Link
                                            href="/terms-and-condition"
                                            className="px-1 p6-underline text-primary-500"
                                            target="_blank"
                                            children={texts.TermsAndConditions}
                                        />
                                        {texts.And}
                                        <Link
                                            href="/privacy-policy"
                                            className="pl-1 underline p6-underline text-primary-500"
                                            target="_blank"
                                            children={texts.PrivacyPolicy}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                )}
                {isMultipleAccount && showSignUp && (
                    <Button
                        id="loginButton"
                        buttonType="primary"
                        widthClass="w-full"
                        isLoading={loading}
                        disabled={createNewAccountLoading}
                        type="submit"
                        buttonText={texts.LoginToAnExistingAccount}
                    />
                )}
                {(!showSignUp || !isMultipleAccount) && (
                    <div className="text-center">
                        <Button
                            id={
                                showEmailInput ? 'gtm-click-SignUp' : 'gtm-click-Login'
                            }
                            type="submit"
                            widthClass="w-full"
                            buttonType="primary"
                            className="mt-3"
                            isLoading={loading}
                            buttonText={
                                showEmailInput ? texts.SignUp : texts.Login
                            }
                        />
                        {message && (
                            <Text
                                content={message}
                                weight="500"
                                className="mt-2 text-semantic-error-base"
                            />
                        )}
                    </div>
                )}
            </form>
            {isMultipleAccount && showSignUp && (
                <Button
                    id="createNewAccountButton"
                    buttonType="secondary"
                    widthClass="w-full"
                    className="mt-3"
                    disabled={loading}
                    isLoading={createNewAccountLoading}
                    onClick={() => handleCreateNewAccount()}
                    buttonText={texts.CreateANewAccount}
                />
            )}

            {!isStatic && !isMultipleAccount && showEmailInput ? (
                <>
                    <Text
                        content={
                            <>
                                {texts.AlreadyHaveAnAccount}{' '}
                                <span
                                    className="p5-underline text-primary-500 cursor-pointer"
                                    onMouseDown={e => e.preventDefault()}
                                    onClick={onSwitchLoginAndSignup}
                                >
                                    {texts.Login}
                                </span>
                            </>
                        }
                        className="p5-regular text-gray-600 text-center mt-4"
                    />
                </>
            ) : null}

            {!isStatic && !showEmailInput && !showSignUp ? (
                <>
                    <Text
                        content={
                            <>
                                {texts.NewToAltGraaf}{' '}
                                <span
                                    className="p5-underline text-primary-500 cursor-pointer"
                                    onMouseDown={e => e.preventDefault()}
                                    onClick={onSwitchLoginAndSignup}
                                >
                                    {texts.SignUp}
                                </span>
                            </>
                        }
                        className="p5-regular text-gray-600 text-center mt-4"
                    />
                </>
            ) : null}

            {fromOpportunitySignup && (
                <>
                    {showEmailInput && (
                        <div className="my-4 text-sm xl:my-2">
                            {texts.AlreadyHaveAnAccount}
                            <span
                                className="pr-1 pl-1 text-yellow-500 cursor-pointer underline"
                                onClick={() => handleLoginToExistingAccount()}
                            >
                                {texts.SignIn}
                            </span>
                        </div>
                    )}
                    {!showEmailInput && (
                        <div className="my-4 text-sm">
                            {texts.CreateAnAccount}
                            <span
                                className="pr-1 pl-1 text-yellow-500 cursor-pointer underline"
                                onClick={createNewAccount}
                            >
                                {texts.SignUp}
                            </span>
                        </div>
                    )}
                </>
            )}

            {showConfirmationModal && (
                <ConfirmationSignUpModal
                    show={showConfirmationModal}
                    setShowConfirmationModal={setShowConfirmationModal}
                    message="Your referral link is not valid. Would you like to continue?"
                    signUp={signUp}
                    setLoading={setLoading}
                    removeReferralCode={removeReferralCode}
                />
            )}
        </>
    );
};
export default LoginModalData;
